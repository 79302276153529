/** @jsx jsx */
import { useMemo } from 'react';
import { jsx } from 'theme-ui';
import {
  ProductValueRealisation,
  ProductValueRealisationProps,
} from '@sprinklr/shared-lib';
import { Product } from '@sprinklr/shared-lib/@types/entities';

import { Container } from '@sprinklr/shared-lib';

// temp type
const ProductValueRealisationTemplate: React.FC<any> = ({
  sectionId,
  ...props
}) => {
  const product: Product = props.product
    ? {
        ...props.product,
        description: props.product?.description?.description,
        pbos: props.product.pbos.map(pbo => ({
          text: pbo?.text?.text,
          highlightText: pbo.highlightText,
          pbucs: pbo.pbucs,
        })),
      }
    : undefined;

  const products: Product[] = props.products
    ? props.products.map(product => ({
        ...product,
        pbos: product.pbos.map(pbo => ({
          text: pbo?.text?.text,
          highlightText: pbo.highlightText,
          pbucs: pbo.pbucs,
        })),
      }))
    : undefined;

  const resolvedProps: ProductValueRealisationProps = {
    headline: props.headline,
    product,
    products,
    text: props.text,
  };
  return (
    <Container id={sectionId}>
      <ProductValueRealisation {...resolvedProps} />
    </Container>
  );
};

export default ProductValueRealisationTemplate;
